/*
 * Copyright © 2024 Adnuntius AS.
 */
import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment-timezone';
import writeXlsxFile from "write-excel-file";

function precision(a) {
  if (!isFinite(a)) {
    return 0;
  }
  let e = 1, p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
}

export class Downloader {

  constructor() {
  }

  saveXls2(theTitle: string, headingFields: Array<string>, dataFields, dataRows, referenceFields: any) {
    const stringFieldIds = ['name', 'labels'];
    const doData2 = function () {
      const data = dataRows;
      const actualData = [];
      _.forEach(data, function (dObj) {
        const innerData = [];
        _.forEach(dataFields, function (column) {
          const refField = _.find(referenceFields, function (refField) {
            return refField.apiId === column || refField.id === column;
          });
          const dataObject = {};
          const value = dObj[column + "Start"] || _.get(dObj, [column, 'count']) || dObj[column];
          if ((refField && refField.isDate) || ((_.isString(value) || _.isDate(value)) && moment(value, true).isValid() && (_.lowerCase(column).indexOf("date") > -1 || _.lowerCase(column).indexOf("time") > -1 || _.lowerCase(column).indexOf("chunk") > -1))) {
            dataObject['type'] = Date;
            dataObject['width'] = 20;
            dataObject['format'] = "yyyy mmm dd HH:mm:ss";
            if (moment(value, true).isValid()) {
              dataObject['value'] = value ? moment.tz(moment(value).format("YYYY-MM-DD HH:mm:ss"), "UTC").toDate() : null;
            }
          } else if ((refField && _.isBoolean(refField.allowDecimals)) || ((!refField || stringFieldIds.indexOf(refField.id) < 0) && (!refField || refField.id !== 'name') && (_.isFinite(value) || (_.isString(value) && value === parseFloat(value).toString())))) {
            const managedValue = _.isString(value) ? parseFloat(value) : _.isFinite(_.get(value, 'amount')) ? value.amount : value;
            dataObject['type'] = Number;
            const decPlaces = Math.min(precision(managedValue), 6);
            dataObject['format'] = decPlaces === 0 ? '0' : '0.' + '0'.repeat(decPlaces);
            dataObject['value'] = managedValue;
          } else {
            dataObject['type'] = String;
            dataObject['value'] = _.isFinite(value) ? '' : value;
          }
          innerData.push(dataObject);
        });
        actualData.push(innerData);
      });
      return actualData;
    };

    const actualData = doData2();
    const HEADER_ROW = _.map(headingFields, function (f, index) {
      const fieldType = actualData[0][index].type;
      const fieldInfo = {value: f, fontWeight: 'bold', align: fieldType === String ? 'left' : 'right'};
      if (fieldType === Date) {
        fieldInfo['width'] = 20;
      }
      return fieldInfo;
    });

    const data = [HEADER_ROW];
    _.forEach(actualData, function (d) {
      data.push(d);
    });

    const updatedTitle = _.endsWith(theTitle, ".xls") ? theTitle + "x" : theTitle;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    writeXlsxFile(data, {
      columns: _.map(HEADER_ROW, function (row) {
        const column = {};
        if (row['width']) {
          column['width'] = row['width'];
        }
        return column;
      }),
      fileName: updatedTitle
    }).then();
  }
}
